import $ from 'jquery';
import Slick from 'slick-carousel';

export default function () {

	var $carousel = $('.js-home-section-slider');

	if($carousel.length > 0) {

		$carousel.slick({
			slidesToShow: 1,
			arrows: false,
			dots: true,
			vertical: true,
			verticalSwiping: false,
			autoplay: true,
  			autoplaySpeed: 3000,
  			speed: 600,
  			pauseOnHover: false,
  			cssEase: 'cubic-bezier(0.425, -0.005, 0.295, 1.000)'
		});

	}
}