import $ from 'jquery';

export default function() {

	$('body').addClass('in');

	var $links = $('a').not('.lightbox_link').not('.scrollTo');

	$links.on('click', function(event){
		var href = $(this).attr('href');

		if(href.indexOf('tel') == -1 && href.indexOf('#') == -1) {
			event.preventDefault();
			out($(this));
		}
	})
}

function out(pLink){

	var href = pLink.attr('href');

	if (typeof href !== typeof undefined && href !== false && href !== "#" ) {

	    setTimeout(function(){

	      	$('body').addClass('out');

	        setTimeout(function(){
	        	window.location = href;
	        },350);
	        
	    },200);
	   
	}
}