import './modules/helpers';
import start from './modules/router';
import inOut from './modules/inOut';
import mobileNav from './modules/mobileNav';
import loader from './modules/loader';
import scroller from './modules/scroller';
import cliniqueCarousel from './modules/cliniqueCarousel';
import homeSectionSlider from './modules/homeSectionSlider';
import servicesSlider from './modules/servicesSlider';

inOut();
mobileNav();
loader();
scroller();
cliniqueCarousel();
homeSectionSlider();
servicesSlider();