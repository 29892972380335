import $ from 'jquery';
import Slick from 'slick-carousel';

export default function () {

	var $carousel = $('.js-services-slider');

	if($carousel.length > 0) {
		var _tempHeight = 0;

		$carousel.slick({
			slidesToShow: 1,
			arrows: false,
			dots: true,
			vertical: true,
			verticalSwiping: true,
			autoplay: false,
  			speed: 1000,
  			//cssEase: 'cubic-bezier(0.250, 0.460, 0.135, 1.020)'
		});


		$(".js-services-slider .c-services-slider__slide").each(function(e){
			var $this = $(this); 

			$this.height($(".slick-list").height());
		});
	}
}