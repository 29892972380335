import $ from 'jquery';

export default function () {

	//Fonction to strigger scroll to different desctions in a page
	$('.scrollTo').click(function(event){
		event.preventDefault();
		smoothScroll($(this).attr('href'));
		return false;
	});

	function smoothScroll(section){ // Smoothscroll to a section
		$('html, body').animate({ scrollTop:$("#"+section).offset().top - 80}, 800);
	} 

}
