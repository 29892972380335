import $ from 'jquery';
import Slick from 'slick-carousel';

export default function () {

	var $carousel = $('.js-clinique-carousel');

	if($carousel.length > 0) {

		$carousel.slick({
			slidesToShow: 4,
			infinite: true,
			prevArrow: '.js-clinique-carousel__prev',
			nextArrow: '.js-clinique-carousel__next',
			responsive: [
		    	{
					breakpoint: 1024,
					settings: {
						slidesToShow: 3,
					}
				},
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 2,
					}
				},
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 1,
					}
				}
			]
		});

	}
}