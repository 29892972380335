import $ from 'jquery';

export default function () {

	$(window).on('load', function(){
		
		setTimeout(removeLoader, 500);
	})

	function removeLoader(){
	    $('html').addClass('loaded');
	    $('body').addClass('active');
	}
}