import $ from 'jquery';

export default function () {

    var menuOuvert = false;

	// $(window).scroll(function(){
	// 	$('.js-mobile-menu').removeClass('active');
	// });

	$('.js-mobile-menu').click(function(){
		$('.js-mobile-menu').removeClass('active');
	})

	$('.js-mobile-menu-btn').click(function(event){
		event.stopPropagation();
		if(!menuOuvert){
			$('.js-mobile-menu').addClass('active');
			menuOuvert = true;
		}
		else{
			$('.js-mobile-menu').removeClass('active');
			menuOuvert = false;
		}
		event.stopPropagation();
	});

	$('body').click(function(){
		if(menuOuvert){
			$('.js-mobile-menu').removeClass('active');
			menuOuvert = false;
		}
	}); 

}
